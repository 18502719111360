import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';


interface Player {
  player_id: number;
  first_name: string;
  last_name: string;
  handedness: string;
  team: string;
  handicap: number;
  tournament_handicap: number;
}

const Players: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState<Player[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const APIURL = process.env.REACT_APP_API_URL;


  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(`${APIURL}/data`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        setData(responseData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [getAccessTokenSilently]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h2>Golfskies League Members</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Handedness</th>
            <th>Team</th>
            <th>Handicap</th>
            <th>Tournament Handicap</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.player_id}>
              <td>{row.player_id}</td>
              <td>{row.first_name}</td>
              <td>{row.last_name}</td>
              <td>{row.handedness}</td>
              <td>{row.team}</td>
              <td>{row.handicap}</td>
              <td>{row.tournament_handicap}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Players;
