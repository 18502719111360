import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Modal from '../components/addNewRoundModal';
import '../css/rounds.css';


const Rounds: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [regularRounds, setRegularRounds] = useState<any[]>([]);
  const [tournamentRounds, setTournamentRounds] = useState<any[]>([]);
  const [modalShow, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);
  const APIURL = process.env.REACT_APP_API_URL;

  const fetchRegularRounds = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${APIURL}/rounds`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      setRegularRounds(responseData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [APIURL, getAccessTokenSilently]);

  const fetchTournamentRounds = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${APIURL}/tournamentRounds`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      setTournamentRounds(responseData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchRegularRounds();
    fetchTournamentRounds();
  }, [fetchRegularRounds, fetchTournamentRounds]);

  const deleteRound = async (roundId: number) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this round?');
    if (!isConfirmed) {
      return;
    }

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${APIURL}/rounds/${roundId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete the round');
      }

      // Remove the deleted round from the local state
      setRegularRounds(regularRounds.filter(row => row.round_id !== roundId));
      setTournamentRounds(tournamentRounds.filter(row => row.round_id !== roundId));
    } catch (error) {
      console.error('Error deleting round:', error);
    }
  };

  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({});

  const toggleExpandRow = (index: number) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const renderRoundTable = (data: any[], title: string) => (
    <div className="table-container">
    <h2>{title}</h2>
    <div className="table-header">
      <div className="table-row">
        <div className="table-cell">Date</div>
        <div className="table-cell">RoundID</div>
        <div className="table-cell">Course Name</div>
        <div className="table-cell">Course Teebox</div>
        <div className="table-cell">Scores</div>
        <div className="table-cell">Actions</div>
      </div>
    </div>
    <div className="table-body">
      {data.map((row, index) => (
        <div className="table-row" key={index}>
          <div className="table-cell" data-label="Date">{row.date}</div>
          <div className="table-cell" data-label="RoundID">{row.round_id}</div>
          <div className="table-cell" data-label="Course Name">{row.course_name}</div>
          <div className="table-cell" data-label="Course Teebox">{row.course_teebox_name}</div>
          <div className="table-cell" data-label="Scores">
            {row.player_scores ? row.player_scores.split(',').map((score: string, idx: number) => (
              <React.Fragment key={idx}>{score}<br /></React.Fragment>
            )) : 'No scores'}
          </div>
          <div className="table-cell" data-label="Actions">
            <button className="btn btn-danger" onClick={() => deleteRound(row.round_id)}>Delete</button>
          </div>
        </div>
      ))}
    </div>
  </div>
);

  return (
    <div>
      <div>
        <button className="btn btn-primary" onClick={handleModalShow}>Add New Round</button>
        <Modal show={modalShow} onHide={handleModalClose} title="Adding a new round" children={undefined}></Modal>
      </div>
      {renderRoundTable(regularRounds, "Regular Season Rounds")}
      <br />
      {renderRoundTable(tournamentRounds, "Major Rounds")}
    </div>
  );
};

export default Rounds;
