// src/pages/About.tsx
import React from 'react';
import Players from './Players'

const About: React.FC = () => {
  return (
      
      <div>Meet the Broskies Golf Association
      <li>Commissionner: Terrence Wong</li>
      <li>President: Patrick Asfour</li>
      </div>
      
      
      );
};

export default About;
