import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';


// Define the type for a standing record
interface StandingRecord {
  player_id: number;
  first_name: string;
  last_name: string;
  total_round_points: number;
  total_round_team_points: number;
  total_tournament_points: number;
  total_tournament_team_points: number;
  total_points: number;
}

interface RegFinishesRecord {
  player_id: number;
  first_name: string;
  last_name: string;
  regular_season_starts: number;
  rank_1_finishes: number;
  rank_2_finishes: number;
  rank_3_finishes: number;
  rank_4_finishes: number;
  rank_5_finishes: number;
  rank_6_finishes: number;
  rank_7_finishes: number;
}

interface AverageScoresRecord {
  player_id: number;
  first_name: string;
  last_name: string;
  average_score: number;
}

const Standings: React.FC = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [standings, setStandings] = useState<StandingRecord[]>([]);
  const [regFinishes, setRegFinishes] = useState<RegFinishesRecord[]>([]);
  const [averageScores, setAverageScores] = useState<AverageScoresRecord[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const APIURL = process.env.REACT_APP_API_URL;


  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get<StandingRecord[]>(`${APIURL}/standings`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setStandings(response.data);
      } catch (err) {
        setError('Failed to fetch standings.');
      } finally {
        setLoading(false);
      }
    };

    const fetchRegFinishes = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get<RegFinishesRecord[]>(`${APIURL}/regfinishes`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRegFinishes(response.data);
      } catch (err) {
        setError('Failed to fetch regular finishes.');
      }
    };

    const fetchAverageScores = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get<AverageScoresRecord[]>(`${APIURL}/averagescores`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAverageScores(response.data);
      } catch (err) {
        setError('Failed to fetch average scores.');
      }
    };

    if (isAuthenticated) {
      fetchStandings();
      fetchRegFinishes();
      fetchAverageScores();
    } else if (!isLoading) {
      loginWithRedirect();
    }
  }, [getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect]);

  if (isLoading || loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <h3>Standings</h3>
      <table>
        <thead>
          <tr>
            <th>Player ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Regular Season Points</th>
            <th>Regular Season Team Points</th>
            <th>Major Points</th>
            <th>Major Team Points</th>
            <th>Total Points</th>
          </tr>
        </thead>
        <tbody>
          {standings.map((record) => (
            <tr key={record.player_id}>
              <td>{record.player_id}</td>
              <td>{record.first_name}</td>
              <td>{record.last_name}</td>
              <td>{record.total_round_points}</td>
              <td>{record.total_round_team_points}</td>
              <td>{record.total_tournament_points}</td>
              <td>{record.total_tournament_team_points}</td>
              <td>{record.total_points}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br></br>
      <h3>Regular Season Finishes</h3>
      <table>
        <thead>
          <tr>
            <th>Player ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Regular Season Starts</th>
            <th>Rank 1 Finishes</th>
            <th>Rank 2 Finishes</th>
            <th>Rank 3 Finishes</th>
            <th>Rank 4 Finishes</th>
            <th>Rank 5 Finishes</th>
            <th>Rank 6 Finishes</th>
            <th>Rank 7 Finishes</th>
          </tr>
        </thead>
        <tbody>
          {regFinishes.map((record) => (
            <tr key={record.player_id}>
              <td>{record.player_id}</td>
              <td>{record.first_name}</td>
              <td>{record.last_name}</td>
              <td>{record.regular_season_starts}</td>
              <td>{record.rank_1_finishes}</td>
              <td>{record.rank_2_finishes}</td>
              <td>{record.rank_3_finishes}</td>
              <td>{record.rank_4_finishes}</td>
              <td>{record.rank_5_finishes}</td>
              <td>{record.rank_6_finishes}</td>
              <td>{record.rank_7_finishes}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br></br>
      <h3>Average Scores (including Majors)</h3>
      <table>
        <thead>
          <tr>
            <th>Player ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Average Score</th>
          </tr>
        </thead>
        <tbody>
          {averageScores.map((record) => (
            <tr key={record.player_id}>
              <td>{record.player_id}</td>
              <td>{record.first_name}</td>
              <td>{record.last_name}</td>
              <td>{record.average_score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Standings;
