import axios from 'axios';
import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useAuth0 } from '@auth0/auth0-react';

interface Tournament {
  tournament_id?: string;
  tournament_name: string;
  start_date: Date;
  end_date?: Date;
  tournament_team_enabled: boolean;
}


interface TournamentFormProps {
  onTournamentCreated: () => void;
  handleClose: () => void;
}

const TournamentForm: React.FC<TournamentFormProps> = ({ onTournamentCreated, handleClose }) => {
  const { getAccessTokenSilently } = useAuth0();
  const APIURL = process.env.REACT_APP_API_URL;

  const [tournament, setTournament] = useState<Tournament>({
    tournament_name: '',
    start_date: new Date(),
    end_date: new Date(),
    tournament_team_enabled: false,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setTournament({
      ...tournament,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleStartDateChange = (date: Date) => {
    setTournament({ ...tournament, start_date: date });
  };

  const handleEndDateChange = (date: Date) => {
    setTournament({ ...tournament, end_date: date });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const payload = {
      tournament_name: tournament.tournament_name,
      start_date: tournament.start_date.toISOString().split('T')[0],
      end_date: tournament.end_date ? tournament.end_date.toISOString().split('T')[0] : null,
      tournament_team_enabled: tournament.tournament_team_enabled,
      tournament_status: 'open',
    };

    try {
      const token = await getAccessTokenSilently();
      await axios.post(`${APIURL}/createTournaments`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Tournament created successfully!');
      setTournament({
        tournament_name: '',
        start_date: new Date(),
        end_date: new Date(),
        tournament_team_enabled: false,
      });
      onTournamentCreated();
      handleClose();
    } catch (error) {
      console.error('There was an error creating the tournament:', error);
      alert('Failed to create tournament. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Tournament Name:</label>
        <br />
        <input
          type="text"
          name="tournament_name"
          value={tournament.tournament_name}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label>Start Date:</label>
        <br />
        <DatePicker
          selected={tournament.start_date}
          onChange={handleStartDateChange}
          dateFormat="yyyy-MM-dd"
          required
        />
      </div>
      <div>
        <label>End Date:</label>
        <br />
        <DatePicker
          selected={tournament.end_date}
          onChange={handleEndDateChange}
          dateFormat="yyyy-MM-dd"
        />
      </div>
      <div>
        <label>Lefties vs Righties Enabled?</label>
        <br />
        <input
          type="checkbox"
          name="tournament_team_enabled"
          checked={tournament.tournament_team_enabled}
          onChange={handleInputChange}
        />
      </div>
      <br />
      <button type="submit" className="btn btn-primary">Submit</button>
    </form>
  );
};

export default TournamentForm;
