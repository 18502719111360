import React, { useState, useEffect } from 'react';
import TournamentWrapper from '../components/TournamentWrapper'; // Adjust the path based on your file structure
import { useAuth0 } from '@auth0/auth0-react';


interface Tournament {
  tournament_id: number;
  tournament_name: string;
  start_date: string;
  end_date: string;
  tournament_status: string;
  tournament_team_enabled: boolean;
}

const Majors: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState<Tournament[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const APIURL = process.env.REACT_APP_API_URL;


  const fetchTournaments = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${APIURL}/tournaments`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      const formattedData = responseData.map((tournament: Tournament) => ({
        ...tournament,
        start_date: tournament.start_date.split('T')[0], // Format to 'YYYY-MM-DD'
        end_date: tournament.end_date ? tournament.end_date.split('T')[0] : '',
      }));
      setData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  const deleteTournament = async (id: number) => {
    try {
      const token = await getAccessTokenSilently();
      await fetch(`${APIURL}/tournaments/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchTournaments(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting tournament:', error);
      alert('Failed to delete tournament. Please try again.');
    }
  };

  const updateTournamentStatus = async (id: number, status: string) => {
    try {
      const token = await getAccessTokenSilently();
      await fetch(`${APIURL}/tournaments/${id}/status`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tournament_status: status }),
      });
      fetchTournaments(); // Refresh the list after status update
    } catch (error) {
      console.error('Error updating tournament status:', error);
      alert('Failed to update tournament status. Please try again.');
    }
  };

  useEffect(() => {
    fetchTournaments();
  }, [getAccessTokenSilently]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div className="App">
        <TournamentWrapper onTournamentCreated={fetchTournaments} /> {/* Pass the callback function */}
      </div>
      <h2>Major Tournaments</h2>
      <table>
        <thead>
          <tr>
            <th>Tournament ID</th>
            <th>Tournament Name</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
            <th>Lefties vs Righties Enabled</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.tournament_id}>
              <td>{row.tournament_id}</td>
              <td>{row.tournament_name}</td>
              <td>{row.start_date}</td>
              <td>{row.end_date}</td>
              <td>
                <select
                  value={row.tournament_status}
                  onChange={(e) => updateTournamentStatus(row.tournament_id, e.target.value)}
                >
                  <option value="open">Open</option>
                  <option value="closed">Closed</option>
                </select>
              </td>
              <td>{row.tournament_team_enabled ? 'Yes' : 'No'}</td>
              <td>
                <button onClick={() => deleteTournament(row.tournament_id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Majors;
